import React from 'react'
import styled from 'styled-components'
import tw from 'tailwind.macro'
import { Parallax } from 'react-spring/renderprops-addons.cjs'

// Components
import Layout from '../components/Layout'
import ProjectCard from '../components/ProjectCard'
import TechnologyCard from "../components/TechnologyCard"

// Elements
import Inner from '../elements/Inner'
import { Title, BigTitle, Subtitle } from '../elements/Titles'

// Views
import Hero from '../views/Hero'
import Projects from '../views/Projects'
import About from '../views/About'
import Contact from '../views/Contact'
import Technologies from "../views/Technologies"

import avatar from '../images/avatar.jpg'

const ProjectsWrapper = styled.div`
  ${tw`flex flex-wrap justify-between mt-8`};
  display: grid;
  grid-gap: 4rem;
  grid-template-columns: repeat(2, 1fr);
  @media (max-width: 1200px) {
    grid-gap: 3rem;
  }
  @media (max-width: 900px) {
    grid-template-columns: 1fr;
    grid-gap: 2rem;
  }
`
const TechnologiesWrapper = styled.div`
  ${tw`flex flex-wrap justify-between mt-8`};
  display: grid;
  grid-gap: 2rem;
  grid-template-columns: repeat(4, 2fr);
  @media (max-width: 1200px) {
    grid-gap: 3rem;
  }
  @media (max-width: 900px) {
    grid-template-columns: 1fr;
    grid-gap: 1rem;
  }
`

const AboutHero = styled.div`
  ${tw`flex flex-col lg:flex-row items-center mt-8`};
`

const Avatar = styled.img`
  ${tw`rounded-full w-32 xl:w-48 shadow-lg h-auto`};
`

const AboutSub = styled.span`
  ${tw`text-white pt-12 lg:pt-0 lg:pl-12 text-2xl lg:text-3xl xl:text-4xl`};
`

const AboutDesc = styled.p`
  ${tw`text-grey-light text-lg md:text-xl lg:text-2xl font-sans pt-6 md:pt-12 text-justify`};
`

const ContactText = styled.p`
  ${tw`text-grey-light font-sans text-xl md:text-2xl lg:text-3xl`};
`

const Footer = styled.footer`
  ${tw`text-center text-grey absolute pin-b p-6 font-sans text-md lg:text-lg`};
`

const Index = () => (
  <>
    <Layout />
    <Parallax pages={7}>
      <Hero offset={0}>
        <BigTitle>
          Hello, <br /> I'm Paul Fox.
        </BigTitle>
        <Subtitle>I'm a passionate web developer who loves solving problems.</Subtitle>
      </Hero>
      <Projects offset={1}>
        <Title>Projects</Title>
        <ProjectsWrapper>
          <ProjectCard
            title="Sellers Place"
            link="https://sellers.azurewebsites.net/"
            bg="linear-gradient(to right, #D4145A 0%, #FBB03B 100%)"
          >
            A SaaS application for farmers market and craft fair event promoters to coordinate and curate their events.
          </ProjectCard>
          <ProjectCard
            title="Operation Code"
            link="https://operationcode.org/"
            bg="linear-gradient(to right, #662D8C 0%, #ED1E79 100%)"
          >
            Military outreach program intended to help teach veterans how to code.
          </ProjectCard>
          <ProjectCard
            title="Public Tree Map"
            link="https://github.com/Public-Tree-Map/public-tree-map"
            bg="linear-gradient(to right, #009245 0%, #FCEE21 100%)"
          >
            A vanilla JavaScript application documenting all ~35,000 public street and park trees in Santa Monica's urban forest.
          </ProjectCard>
          <ProjectCard
            title="Kat Kimball"
            link="https://katkimball.com/"
            bg="linear-gradient(to right, #D585FF 0%, #00FFEE 100%)"
          >
            An actress portfolio page created to help enhance personal brand.
          </ProjectCard>
        </ProjectsWrapper>
      </Projects>
      <Technologies offset={3}>
        <Title>Technologies</Title>
        <TechnologiesWrapper>
          <TechnologyCard
            title="C#"
            bg="linear-gradient(to right, #ffafbd 0%, #ffc3a0 100%)"
          >
          </TechnologyCard>
          <TechnologyCard
            title=".Net"
            bg="linear-gradient(to right, #2193b0 0%, #6dd5ed 100%)"
          >
          </TechnologyCard>
          <TechnologyCard
            title="React"
            bg="linear-gradient(to right, #42275a 0%, #734b6d 100%)"
          >
          </TechnologyCard>
          <TechnologyCard
            title="Angular"
            bg="linear-gradient(to right, #bdc3c7 0%, #2c3e50 100%)"
          >
          </TechnologyCard>
          <TechnologyCard
            title="SQL"
            bg="linear-gradient(to right, #614385 0%, #516395 100%)"
          >
          </TechnologyCard>
          <TechnologyCard
            title="AWS"
            bg="linear-gradient(to right, #eecda3 0%, #ef629f 100%)"
          >
          </TechnologyCard>
          <TechnologyCard
            title="JavaScript"
            bg="linear-gradient(to right, #02aab0 0%, #00cdac 100%)"
          >
          </TechnologyCard>
          <TechnologyCard
            title="Python"
            bg="linear-gradient(to right, #000428 0%, #004e92 100%)"
          >
          </TechnologyCard>
          <TechnologyCard
            title="NodeJS"
            bg="linear-gradient(to right, #ff512f 0%, #dd2476 100%)"
          >
          </TechnologyCard>
          <TechnologyCard
            title="HTML"
            bg="linear-gradient(to right, #c33764 0%, #1d2671 100%)"
          >
          </TechnologyCard>
          <TechnologyCard
            title="CSS"
            bg="linear-gradient(to right, #ed4264 0%, #ffedbc 100%)"
          >
          </TechnologyCard>
          <TechnologyCard
            title="Redux"
            bg="linear-gradient(to right, #aa076b 0%, #61045f 100%)"
          >
          </TechnologyCard>
        </TechnologiesWrapper>
      </Technologies>
      <About offset={5}>
        <Title>About</Title>
        <AboutHero>
          <Avatar src={avatar} alt="Paul Fox" />
          <AboutSub>
            I have a lifelong passion for learning. I love solving complex problems and finding creative solutions. I enjoy being presented with challenges.
          </AboutSub>
        </AboutHero>
        <AboutDesc>
          I like to keep busy. Currently I am a Junior Developer at 1iota. I like contributing to open source projects, and formally have taught JavaScript/HTML beginner classes, mentored veterans for Operation Code, and done freelance work for individuals and small businesses.
          When I'm not keeping myself up late working, I enjoy thrill seeking. I like to go white water rafting and skydiving. My next goal is to go shark cage diving. I also love to try new restaurants.
          I enjoy sharing with others, and hope to give others opportunity to find something they may love doing.
        </AboutDesc>
      </About>
      <Contact offset={6}>
        <Inner>
          <Title>Get in touch</Title>
          <ContactText>
            <a href="mailto:paul.fox310@gmail.com">Email</a> me or find me on other platforms:{' '}
            <a href="https://github.com/paulfox310">GitHub</a> &{' '}
            <a href="https://www.linkedin.com/in/paul-fox-developer/">LinkedIn</a>
          </ContactText>
        </Inner>
        <Footer>
          {/* &copy; 2019 by Gatsby Starter Portfolio Cara.{' '}
          <a href="https://github.com/LekoArts/gatsby-starter-portfolio-cara">GitHub Repository</a>. Made by{' '}
          <a href="https://www.lekoarts.de">LekoArts</a>. */}
        </Footer>
      </Contact>
    </Parallax>
  </>
)

export default Index
