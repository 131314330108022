const tailwind = require('../tailwind')

module.exports = {
  //pathPrefix: '/', // Prefix for all links. If you deploy your site to example.com/portfolio your pathPrefix should be "/portfolio"

  siteTitle: 'Paul Fox - Web Developer', // Navigation and Site Title
  siteTitleAlt: 'Paul Fox', // Alternative Site title for SEO
  siteTitleShort: 'Paul Fox', // short_name for manifest
  siteHeadline: 'Creating dynamic user experiences and writing clean code.', // Headline for schema.org JSONLD
  siteUrl: 'https://paulfox.dev', // Domain of your site. No trailing slash!
  siteLanguage: 'en', // Language Tag on <html> element
  siteLogo: '/pflogo.png', // Used for SEO and manifest
  siteDescription: 'Professional Development Portfolio for Paul Fox',
  author: 'Paul Fox', // Author for schema.org JSONLD

  // siteFBAppID: '123456789', // Facebook App ID - Optional
  userTwitter: '@paulfox310', // Twitter Username
  ogSiteName: 'Paul Fox', // Facebook Site Name
  ogLanguage: 'en_US', // Facebook Language
  googleAnalyticsID: 'UA-157879265-1',

  // Manifest and Progress color
  themeColor: tailwind.colors.orange,
  backgroundColor: tailwind.colors.blue,
}
